import { Main } from "./Main";
import "../Commons/Common.css";
import { TABLE_BORDER_STYLE, TABLE_HEADER_STYLE } from "../Commons/Common";

export default function Profiles() {
    return Main(() => (
        <div className="center" style={{ position: "fixed", width: "60%" }}>
            <h5 style={{ margin: "24px 0" }}>公司简介</h5>
            <table style={{ position: "fixed", fontSize: "16px", lineHeight: "28px", borderCollapse: "collapse" }}>
                <tbody>
                    <tr>
                        <td style={TABLE_HEADER_STYLE}>公司名称</td>
                        <td style={TABLE_BORDER_STYLE}>上海灵炎网络科技有限公司</td>
                    </tr>
                    <tr>
                        <td style={TABLE_HEADER_STYLE}>经营范围</td>
                        <td style={TABLE_BORDER_STYLE}>
                            从事网络科技、计算机信息科技领域内的技术开发、技术咨询、技术服务、技术转让，设计、制作各类广告，计算机软件开发，网页设计，网站设计，计算机、软件及辅助设备批发、零售。
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    ));
}
