import { Main } from "../Main";
import "../../Commons/Common.css";
import { TABLE_BORDER_STYLE, TABLE_HEADER_STYLE } from "../../Commons/Common";

export default function ContactWithEmail() {
    return Main(() => (
        <div className="center" style={{ position: "fixed", width: "60%" }}>
            <h5 style={{ margin: "24px 0" }}>联系我们</h5>
            <table style={{ position: "fixed", width: "100%", fontSize: "16px", lineHeight: "28px", borderCollapse: "collapse" }}>
                <tbody>
                    <tr>
                        <td style={TABLE_HEADER_STYLE}>电子邮件</td>
                        <td style={{ ...TABLE_BORDER_STYLE, position: "relative", left: "1%" }}>general@soulfire.tech</td>
                    </tr>
                </tbody>
            </table>
        </div>
    ));
}
