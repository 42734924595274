export const DOCUMENT_BACKGROUND_COLOR = "#eee"
export const BODY_BACKGROUND_COLOR = "#ddd"
export const CONTENT_BACKGROUND_COLOR = "#ccc"
export const POPUP_BACKGROUND_COLOR = "#bbb"
export const SELECTED_BACKGROUND_COLOR = "#aaa"

export const TEXT_COLOR = "#333"

export const TABLE_HEADER_STYLE: React.CSSProperties = {
    width: "72px",
    border: "1px solid black",
};

export const TABLE_BORDER_STYLE: React.CSSProperties = {
    border: "1px solid black",
};