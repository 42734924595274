import { JsxElement } from "typescript";
import { TEXT_COLOR } from "../Commons/Common";
import Menu from "./Components/Menu";
import "../Commons/Common.css";

const HEADER_MARGIN = 16;
const HEADER_FONT_SIZE = 64;
const HEADER_FONT_SIZE_PX = `${HEADER_FONT_SIZE}px`;
const HEADER_HEIGHT = HEADER_FONT_SIZE + HEADER_MARGIN * 2 + 40;

export function Main(Content: () => JSX.Element) {
    return (
        <div style={{ height: `${HEADER_HEIGHT}px`, fontSize: HEADER_FONT_SIZE_PX, fontFamily: "cursive" }}>
            <div id="header" className="vertical-center" style={{}}>
                <a style={{ color: TEXT_COLOR, display: "flex", justifyContent: "center" }}>上海灵炎网络科技有限公司</a>

                <div style={{ position: "relative", marginTop: `${HEADER_MARGIN}px` }}>
                    <Menu />
                </div>
            </div>

            <div id="body" style={{ height: `${window.innerHeight - HEADER_HEIGHT}px` }}>
                <div className="vertical-center-10">
                    <Content />
                </div>

                <div className="horizontal-center" style={{ position: "fixed", bottom: "0", fontSize: "14px", fontFamily: "initial", fontWeight: "normal" }}>
                    网络备案号&nbsp;&nbsp;&nbsp;&nbsp;沪ICP备18020053号
                </div>
            </div>
        </div>
    );
}
