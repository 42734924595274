import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Home from "./Pages/Home";
import reportWebVitals from "./reportWebVitals";
import { DOCUMENT_BACKGROUND_COLOR } from "./Commons/Common";
import { BrowserRouter } from "react-router-dom";

document.body.style.backgroundColor = DOCUMENT_BACKGROUND_COLOR;

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
