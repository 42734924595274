import "./App.css";
import Profiles from "./Pages/Profiles";
import Home from "./Pages/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Samples from "./Pages/Samples";
import ContactWithEmail from "./Pages/Contacts/ContactWithEmail";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/Profiles" element={<Profiles />} />
                <Route path="/Samples" element={<Samples />} />
                <Route path="/Contacts/Email" element={<ContactWithEmail />} />
            </Routes>
        </BrowserRouter>
    );
    // return (<div>aaa</div>)
}

export default App;
