import { Main } from "./Main";
import "../Commons/Common.css";
import React from "react";

export default function Samples() {
    return Main(() => (
        <div className="center" style={{ position: "fixed", width: "60%" }}>
            <h5 className="horizontal-center" style={{ margin: "24px 0", textAlign: "center" }}>
                案例展示
            </h5>
            <table className="horizontal-center" style={{ position: "fixed", fontSize: "26px", lineHeight: "28px", borderCollapse: "collapse" }}>
                <tbody>
                    <tr>
                        <td>网站建设中</td>
                    </tr>
                </tbody>
            </table>
        </div>
    ));
}
