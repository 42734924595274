import "./Menu.css";
import { CONTENT_BACKGROUND_COLOR, DOCUMENT_BACKGROUND_COLOR, POPUP_BACKGROUND_COLOR, TEXT_COLOR } from "../../Commons/Common";

function Menu() {
    return (
        <div style={{ position: "relative", backgroundColor: CONTENT_BACKGROUND_COLOR, color: TEXT_COLOR, fontSize: "32px" }}>
            <table id="items" style={{ position: "relative", left: "50%", transform: "translateX(-50%)", borderSpacing: "0" }}>
                <tbody id="tbody">
                    <tr>
                        <td className="menu-td" width="160px">
                            <a className="normal-link" style={{ display: "flex", justifyContent: "center" }} href="/">
                                公司首页
                            </a>
                        </td>
                        <td className="menu-td" width="160px">
                            <a className="normal-link" style={{ display: "flex", justifyContent: "center" }} href="/Profiles">
                                公司简介
                            </a>
                        </td>
                        <td className="menu-td" width="160px">
                            <a className="normal-link" style={{ display: "flex", justifyContent: "center" }} href="/Samples">
                                案例展示
                            </a>
                        </td>
                        <td width="160px">
                            <div className="dropdown" style={{ display: "flex", justifyContent: "center" }}>
                                联系我们
                                <div className="dropdown-content" style={{ marginTop: "38px", backgroundColor: POPUP_BACKGROUND_COLOR }}>
                                    {/* <a style={{ display: "flex", justifyContent: "center", width: "160px", marginTop: "6px" }}>电话联系</a>  */}
                                    <a
                                        className="normal-link"
                                        style={{ display: "flex", justifyContent: "center", width: "160px", marginTop: "6px" }}
                                        href="/Contacts/Email">
                                        邮箱联系
                                    </a>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default Menu;
