import React from "react";
import logo from "./logo.svg";
import Menu from "./Components/Menu";
import { TEXT_COLOR } from "../Commons/Common";
import { Main } from "./Main";
import "../Commons/Common.css";
// import './App.css';

export default function Home() {
    return Main(() => (
        <div className="vertical-center">
            <p className="welcome" style={{ position: "relative", justifyContent: "center", color: TEXT_COLOR }}>
                欢迎光临
            </p>
            <p className="welcome-content" style={{ position: "fixed", justifyContent: "center", fontSize: "84px", textAlign: "right", color: TEXT_COLOR }}>
                上海灵炎网络科技有限公司
            </p>
        </div>
    ));
}
